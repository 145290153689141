<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="12"
        md="6"
      >
        <ecommerce-medal :data="data" />

      </b-col>

    </b-row>
    <!-- Graphic -->
    <!-- <div class="card card-body" style="display:none;">
      <b-row class="match-height">
        <b-col
          xl="9"
          md="12"
        >
        <h3>Marcas que an generado mas jugadas</h3>
        <e-charts autoresize :options="pie" ref="pie" style="width: auto" />
        <h3>Jugadores que mas han ganado</h3>
        <b-table
          class="position-relative"
          :items="dataplayPlayer"
          responsive
          :fields="fields">
          <template #cell(amount)="data">
            {{ data.item.amount | currency({ symbol: "" }) }}
          </template>
        </b-table>
        <b-table-simple
          class="position-relative"
          responsive>
          <b-tr  variant="success">
            <b-td style="width: 50%;color: black;">Total:</b-td>
            <b-td class="text-center" style="color: black;">
              {{ this.sumTotal | currency({ symbol: "" }) }}
            </b-td>
          </b-tr>
        </b-table-simple>
      </b-col>
      <b-col md="3">
        <b-col class="p-0" md="12">
          <b-card no-body class="p-0 py-1">
            <HierarchyUsers
              @selectUser="selectUserHierarchy"
              ref="HerarchiComponent"
            />
          </b-card>
        </b-col>
      </b-col>
      </b-row>
    </div> -->
      <SocketIoTest></SocketIoTest>
  </section>
</template>

<script>
import axios from "@axios"
import { BRow, BCol } from 'bootstrap-vue'
import EcommerceMedal from './ecommerce/EcommerceMedal.vue'
//import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
// Importar librerias de grafico
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import SocketIoTest from "@/views/extensions/socket-io/SocketIoTest.vue";

export default {
  components: {
    SocketIoTest,
    BRow,
    BCol,
    EcommerceMedal,
    ECharts,
    //HierarchyUsers,
  },
  data(){
    return {
      data:{
        saleToday:20
      },
      fields: [
        { key: "name", sortable: true, label: "Jugador" },
        { key: "amount", sortable: true, label: "Monto" },
      ],
      idUserSelect: "",
      reportMarcas: [],
      dataplayPlayer: [],
      sumTotal: 0,
      pie:{
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'center',
          data: []
        },
        series: [
          {
            name: 'Marcas que hayan generado mas profit',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            color: ['#FF9F43','#28C76F','#EA5455','#87ceeb','#7367F0'],
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
    }
  },
  // methods: {
  //   selectUserHierarchy({ id }) {
  //     this.idUserSelect = id
  //     this.dashboardReport()
  //   },
  //   async dashboardReport(){
  //     this.pie.series[0].data = []
  //     const VUE_APP_URL = process.env.VUE_APP_URL;
  //     const whitelabel = this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
  //     const currency = this.$store.state.whitelabelCurrencyNabvar.currency;
  //     const params = {
  //       "id": this.idUserSelect,
  //       "whitelabel": whitelabel,
  //       "currency": currency,
  //     };
  //     const result = axios
  //         .post(`${VUE_APP_URL}/dashboardReport`, params)
  //         .then((r) => (
  //           this.pie.series[0].data.push(...r.data.i.reportMarcas),
  //           this.dataplayPlayer = r.data.o.reportplayPlayer,
  //           this.sumTotal = r.data.o.sumTotal
  //           )
  //         )
  //         .catch((error) => console.log(error.response));
  //     return result
  //   }
  // },
}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>