<template>
  <div class="d-flex" style="display: none !important;">
    <div class="mr-2 p-2 round" style="background-color: #b9b9c3; width: 40%">
      <h5 class="mb-2">Socket Features From Global State</h5>
      <p v-if="isConnectedGS">We're connected to the server!</p>
      <p v-else>Socket disconnect</p>
      <p>Message from server: "{{ messageFromServerGS }}"</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import socketIoStore from "@/store/socket-io/socket-io.store";

export default {
  name: "SocketIoTest",
  data() {
    return {
      isConnectedLC: false,
      socketMessage: "",
    };
  },
  computed: {
    ...mapState({
      isConnectedGS: (state) => state.socketIoStore.isConnected,
      messageFromServerGS: (state) => state.socketIoStore.socketMessage,
    }),
  },
  methods: {
    pingServer() {
      // Send the "pingServer" event to the server.
      this.$socket.emit("events", "PING!");
    },
  },
};
</script>

<style scoped></style>
